import { Grid, Button } from "@mui/material"
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { React, useState } from 'react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const MappingIntsructionTable = ({
    columns,
    rows,
    HiddenColumnlist,
    isLoading,
    headFilter,
    restname
}) => {
    const [ColumnVisibility, setColumnVisibility] = useState(HiddenColumnlist)
    const customTheme = createTheme({
        palette: {
            primary: {
                main: '#19A73F' // Replace with your desired color
            }
        }
    })

    const handleExportData = async (rows) => {


        const tableData = rows.map((row) => Object.values(row.original));


        const tableDatas = tableData.map((row) => ({
            id: row[0],
            Rest_id: row[1],
            Cat_id: row[2],
            Cat_name: row[3],
            Details: row[4],
            Purveyor: row[5],
            Review_status: row[6]
        }));




        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(`Mapping Instructions_${restname}.xlsx`);
        const restIdValue = 'Restaurant Name: ' + restname;
        worksheet.addRow([restIdValue]);

        worksheet.mergeCells('A1:D1');
        const restIdCell = worksheet.getCell('A1');
        restIdCell.font = { bold: true };
        restIdCell.alignment = { horizontal: 'center' };

        worksheet.getCell('A2').value = 'Purveyor';
        worksheet.getCell('B2').value = 'Description';
        worksheet.getCell('C2').value = 'Fobe Category Name';
        worksheet.getCell('D2').value = 'Review Status';


        worksheet.getCell('A2').font = { bold: true };
        worksheet.getCell('B2').font = { bold: true };
        worksheet.getCell('C2').font = { bold: true };
        worksheet.getCell('D2').font = { bold: true };

        worksheet.getColumn('A').width = 20;
        worksheet.getColumn('B').width = 55;
        worksheet.getColumn('C').width = 20;
        worksheet.getColumn('D').width = 20;

        let rowIndex = 3;

        const filteredData = tableDatas.map(item => ({
            Purveyor: item.Purveyor,
            Details: item.Details,
            Cat_name: item.Cat_name,
            Review_status: item.Review_status == 1 ? 'Reviewed' : 'Yet to Review',
        }));

        filteredData.forEach(data => {
            worksheet.getCell(`A${rowIndex}`).value = data.Purveyor;
            worksheet.getCell(`B${rowIndex}`).value = data.Details;
            worksheet.getCell(`C${rowIndex}`).value = data.Cat_name;
            worksheet.getCell(`D${rowIndex}`).value = data.Review_status;
            rowIndex++;
        });

        const buffer = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([buffer]), `Mapping Instructions_${restname}.xlsx`);
    };


    const tabledate = useMaterialReactTable({
        columns: columns,
        data: rows,
        enableColumnOrdering: true,
        enableColumnPinning: true,
        enableFullScreenToggle: false,
        enableDensityToggle: false,
        enableColumnResizing: true,
        enableGrouping: true,
        autoResetPageIndex: false,
        layoutMode: 'semantic',
        muiPaginationProps: {
            rowsPerPageOptions: ['10', '15', '50', '100']
        },
        layoutMode: 'semantic',
        // initialState: {
        //     columnPinning: { right: ['Review_status', 'Cat_id'] }
        // },
        state: {
            density: 'compact',
            columnVisibility: ColumnVisibility,
            isLoading
        },
        onColumnVisibilityChange: setColumnVisibility,
        renderTopToolbarCustomActions: ({ table }) => {
            return (
                <>
                    <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                        {headFilter}
                        <Button
                            onClick={() => { handleExportData(table.getPrePaginationRowModel().rows) }}
                            startIcon={<FileDownloadIcon />}
                            disabled={rows.length == 0}
                        >
                            Export
                        </Button>
                    </div>
                </>
            )
        }
    })

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <ThemeProvider theme={customTheme}>
                        <MaterialReactTable table={tabledate} />
                    </ThemeProvider>
                </Grid>
            </Grid>
        </>
    )
};
export default MappingIntsructionTable;
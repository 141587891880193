import MappingIntsructionTable from "./MappingInstructionTable";
import "./MappingInstruction.css";
import { React, useEffect, useMemo, useState } from "react"
import { Link, useNavigate } from "react-router-dom";
import Header from "../../Header/header";
import { Select, DatePicker, Typography, Modal, Button, Radio, Drawer, Switch, Skeleton } from "antd";
import { fobeAxios } from "../../middleware/interceptor";
import dayjs from 'dayjs';
import { ToastContainer, toast } from "react-toastify";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { HistoryOutlined } from '@ant-design/icons';
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import ChangeLog from "./ChangeLog";
const MappingIntsruction = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [loginUserName, setLoginUserName] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
    const navigate = useNavigate();
    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };
    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
        localStorage.clear();
        navigate("/");
    };
    const handleMenuCloseout = () => {
        setAnchorEl(null);
        setMobileMoreAnchorEl(null);
    };
    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };
    const [restaurantList, setRestaurantList] = useState([]);
    const [globalRestaurantList, setglobalRestaurantList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [mappingInfo, setmappingInfo] = useState(false);
    const [data, setData] = useState([]);
    const [statistics, setStatistics] = useState([])
    // const [dateRange, setDateRange] = useState([dayjs().subtract(7, 'days'), dayjs()]);
    const [selectedRestaurant, setSelectedRestaurant] = useState();
    const [accountManagerValue, setAccountManagerValue] = useState();
    const [accountTypeValue, setAccountTypeValue] = useState();

    const [categoryList, setCategoryList] = useState([]);
    const [reallocateOption, setReallocateOption] = useState(1);
    const [changedLineItemList, setChangedLineItemList] = useState([]);
    const [restname, setRestname] = useState();
    const [open, setOpen] = useState(false);
    const [changeLogList, setChangeLogList] = useState([]);
    const [hasSkeletonShow, setHasSkeletonShow] = useState(false)
    const [accountManger, setAccountManager] = useState();
    const accountType = [
        { label: "Fobesoft", value: 1 },
        { label: "Fobegolf", value: 5 },
        { label: "Sysco", value: 3 }
    ];
    const onClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        GetRestaurantList();
    }, []);
    // const handleDateAndRestaurantChange = () => {
    //     // let temp = Number(selectedRestaurant?.split('_')[1]);
    //     // GetFobeCatForRestaurant(temp);
    //     GetData(temp, dateRange);
    // }
    const GetRestaurantList = async () => {
        await fobeAxios.get("api/Daily/GetRestaurants_InvoiceAccess").then((res) => {
            let temp = [];
            let managerList = [];

            res.data.rest_list.map((x, index) => {
                temp = [
                    ...temp,
                    { label: x.company_name, value: `${x.company_name}_${x.rest_id}_${x.Role_id ? x.Role_id : 0}`, account_id: x.account_id },
                ];
            });
            res.data.Acc_Manager.map((x, index) => {
                managerList = [
                    ...managerList,
                    { label: x.Rolename, value: x.Role_id, account_id: x.account_id },
                ];
            });
            setAccountManager(managerList);
            setSelectedRestaurant(`${res.data.rest_list[0].company_name}`);
            setRestname(res.data.rest_list[0].company_name);
            setRestaurantList(temp);
            setglobalRestaurantList(temp);
            GetFobeCatForRestaurant(res.data.rest_list[0].rest_id)
            GetData(`${res.data.rest_list[0].company_name}_${res.data.rest_list[0].rest_id}`);
        }).catch((error) => { console.log(error); setLoading(false) })
    };
    // const GetFobeCategoryList = async (rest_id) => {
    //     var budget_month = new Date().getMonth() + 1;
    //     var budge_date = new Date().getDate();
    //     var budget_year = String(new Date().getFullYear());
    //     await fobeAxios
    //         .get(
    //             "/Api/Daily/GetInvoice_foroperations/" +
    //             budget_year +
    //             "/" +
    //             budget_month +
    //             "/" +
    //             budge_date +
    //             "/" +
    //             rest_id
    //         )
    //         .then(async (res) => {
    //             var data = res.data.Invoice_details;
    //             let catlist = [];
    //             data.map((x) => {
    //                 catlist = [
    //                     ...catlist,
    //                     {
    //                         value: x.Cat_name,
    //                         label: x.Cat_name,
    //                     },
    //                 ];
    //             });
    //         });
    // };
    const GetFobeCatForRestaurant = async (Rest_id) => {
        await fobeAxios.get(`/Api/Daily/GetInvoice_foroperations/
            ${new Date().getFullYear()}/${new Date().getMonth()}/
            ${new Date().getDate()}/${Rest_id}`).then(res => {
            let temp = [];
            res.data.Invoice_details.map((cat) => {
                temp.push({
                    value: `${cat.Cat_name}_${cat.Cat_id}`,
                    label: cat.Cat_name,
                })
            })
            setCategoryList(temp);
        }).catch(err => {
            console.log(err)
        })
    }
    const GetData = async (rest_id) => {
        await fobeAxios.get(`api/Invoice/Getinvoicedata/${Number(rest_id.split('_')[1])}`).then(res => {
            if (res.data) {
                setData(res.data.InvoiceData.map((a) => { return { ...a, Review_status: a.Review_status == 0 ? 2 : a.Review_status } }));
                setLoading(false);
            } else {
                toast.warning("No data found")
            }
        }).catch((err) => { console.log(err); toast.warning("No data found"); setLoading(false); })
    }

    const ChangeCategory = async (value, row) => {
        let req = {
            "Rest_id": row.original.Rest_id,
            "Cat_id": row.original.Cat_id,
            "Cat_name": row.original.Cat_name,
            "Details": row.original.Details,
            "Purveyor": row.original.Purveyor,
            "year_id": 2024
        }
        await fobeAxios.post(`api/Invoice/Getlineitemstatistics`, req).then((res) => {
            setChangedLineItemList({ ...row.original, new_cat: value, index: row.index });
            if (res?.data?.Statistics.length > 0) {
                setmappingInfo(true);
                setStatistics(res?.data?.Statistics);
            }
        }).catch(err => { console.log(err) })
    }
    const handleChangeRestaurant = (value) => {
        setLoading(true);
        setSelectedRestaurant(value);
        setRestname(value.split('_')[0]);
        GetFobeCatForRestaurant(Number(value.split('_')[1]))
        GetData(value);
    }

    useEffect(() => {
        let temp = globalRestaurantList;
        if (globalRestaurantList?.length > 0) {
            if (accountManagerValue || accountTypeValue) {
                let result = temp?.filter(list => { return compare(list, accountTypeValue, accountManagerValue) });
                setRestaurantList(result);
                if (result.length > 0) {
                    handleChangeRestaurant(result[0]?.value);
                } else {
                    setSelectedRestaurant(undefined)
                    setData([])
                }
            } else {
                setRestaurantList(temp);
                handleChangeRestaurant(temp[0]?.value);
            }
        }
    }, [accountManagerValue, accountTypeValue])
    const compare = (list, filterOne, filterTwo) => {
        let token = true;
        if (filterOne) {
            if (list.account_id != filterOne) {
                token = false;
            }
        }
        if (filterTwo) {
            if (list.value.split('_')[2] != filterTwo) {
                token = false;
            }
        }
        return token;
    }

    const ReMapCategory = async (value, list) => {
        console.log(value, list)
        let req = {
            Rest_id: list.Rest_id,
            Details: list.Details,
            new_cat_id: Number(list.new_cat.split("_")[1]),
            old_cat_id: list.Cat_id,
            Mappingcatname: list.new_cat.split("_")[0],
            oldCat_name: list.Cat_name,
            key: value
        };
        await fobeAxios.post("api/Invoice/updateinvoicedata/", req).then(res => {
            if (res.data.Status === 1) {
                toast.success("Category reallocated successfully");
                setData(data.map((a) => {
                    return a.id === list.id ? {
                        ...a,
                        Review_status: 1, Cat_name: list.new_cat.split("_")[0], Cat_id: Number(list.new_cat.split("_")[1])
                    } : a
                }));
                // GetData(selectedRestaurant);
                setmappingInfo(false);
            } else {
                toast.warning("Category reallocated failed");
            }

        }).catch((err) => (console.log(err)));
    }
    const handleReallocatopnChange = (value) => {
        setReallocateOption(value.target.value);
    }
    // const handleCalendarChange = (date, dateString) => {
    //     setDateRange([dayjs(dateString[0]), dayjs(dateString[1])]);
    //     handleDateAndRestaurantChange(selectedRestaurant, dateString);
    // }
    const handleReviewChange = async (req) => {
        await fobeAxios.post(`api/Invoice/UpdateReview_status/${req.id}`).then((res) => {
            if (res.data.status == 1) {
                toast.success("The line item was reviewed successfully.");
                setData(data.map((a) => { return a.id === req.id ? { ...a, Review_status: 1 } : a }));
                // GetData(selectedRestaurant);
            } else {
                toast.warning("Faild to updated")
            }
        }).catch((err) => { console.error(err) })
    }
    const GetChangeLog = async (Rest_id, detail_id) => {

        await fobeAxios.get(`api/Invoice/Getmappinginstructionlog/${Rest_id}/${detail_id}`).then((res) => {
            setChangeLogList(res.data.HistoryDetails);
            setOpen(true)
        }).catch((err) => { console.error(err) });
    }
    const columns = useMemo(() => [
        {
            accessorKey: "Purveyor",
            header: "Purveyor",
            editable: false,
            grow: false,
            size: 50,
            muiTableHeadCellProps: {
                align: "left",
            },
            muiTableBodyCellProps: {
                align: "left",
            },
        },
        {
            accessorKey: "Details",
            header: "Description",
            editable: false,
            minSize: 100,
            maxSize: 200,
            size: 200,
            grow: true,
            muiTableHeadCellProps: {
                align: "left",
            },
            muiTableBodyCellProps: {
                align: "left",
            },
        },
        {
            accessorKey: "Cat_name",
            header: "Fobe Category",
            editable: false,
            minSize: 200,
            maxSize: 400,
            size: 200,
            grow: false,
            enableColumnResizing: false,
            enableColumnPinning: true, filterVariant: 'select',
            filterSelectOptions: [
                { label: 'All', value: 0 },
                ...categoryList.map(a => a.value.split('_')[0])
            ],
            muiTableHeadCellProps: {
                align: "left",
            },
            muiTableBodyCellProps: {
                align: "left",
            },
            Cell: ({ row }) => {
                return (
                    <>
                        <Select
                                style={{ width: '90%' }}
                            optionfilterprop="children"
                            value={row.original.Cat_name}
                            size="small"
                            showSearch
                            onChange={(value) => { ChangeCategory(value, row) }}
                            options={categoryList}
                        />
                    </>
                );
            },
        },
        {
            accessorKey: "Review_status",
            header: "Review",
            editable: false,
            grow: false,
            minSize: 5,
            maxSize: 5,
            size: 5,
            filterVariant: 'select',
            filterSelectOptions: [
                { label: 'All', value: 0 },
                { label: 'Yet to review', value: 2 },
                { label: 'Reviewed', value: 1 },
            ],
            enableColumnResizing: false,
            enableColumnPinning: true,
            enableColumnActions: false,
            enableColumnOrdering: false, 
            enableGrouping: false,
            muiTableHeadCellProps: {
                align: "left",
            },
            muiTableBodyCellProps: {
                align: "center",
            },
            Cell: ({ row }) => {
                return (

                    <Switch
                        disabled={row.original.Review_status === 1}
                        checked={row.original.Review_status == 1}
                        value={row.original.Review_status}
                        size="small"
                        onChange={() => { handleReviewChange(row.original) }}
                    />
                );
            },
        },
        {
            accessorKey: "Cat_id",
            header: "History",
            enableSorting: false,
            enableColumnOrdering: false, 
            editable: false,
            size: 5,
            minSize: 5,
            maxSize: 5,
            grow: false,
            enableColumnFilter: false,
            enableColumnResizing: false,
            enableColumnFilter: false,
            enableColumnActions: false,
            enableGrouping: false,
            muiTableHeadCellProps: {
                align: "left",
            },
            muiTableBodyCellProps: {
                align: "center",
            },
            Cell: ({ row }) => {
                return (
                    <>
                        <div style={{ display: 'flex', gap: '10px', justifyContent: 'center' }} >
                            <HistoryOutlined
                        style={{ color: '#19a73f', fontSize: '17px' }}
                        onClick={() => { GetChangeLog(row.original.Rest_id, row.original.id); setChangedLineItemList(row.original) }}
                    />

                        </div>
                    </>
                );
            },
        },
    ], [data])
    const headFilter = [
        <>
            {/* <div style={{ display: 'flex', gap: '10px' }}> */}
            <div>
                <Select
                    size="medium"
                    style={{ width: '140px' }}
                    showSearch
                    allowClear
                    placeholder="Account Type"
                    value={accountTypeValue}
                    options={accountType}
                    onChange={(value) => { setAccountTypeValue(value) }}
                />
            </div>
            <div>
                <Select
                    size="medium"
                    style={{ width: '180px' }}
                    showSearch
                    allowClear
                    placeholder="Account Manager"
                    value={accountManagerValue}
                    options={accountManger}
                    onChange={(value) => { setAccountManagerValue(value) }}
                />
            </div>
            <div>
                <Select
                    size="medium"
                    style={{ width: '200px' }}
                    showSearch
                    placeholder="Restaurants"
                    value={selectedRestaurant}
                    options={restaurantList}
                    onChange={handleChangeRestaurant}
                />
            </div>
            {/* <div>
                    <RangePicker
                        value={dateRange}
                        presets={rangePresets}
                        format={'MM-DD-YYYY'}
                        onChange={handleCalendarChange}
                    />
                </div> */}
            {/* </div> */}
        </>
    ]
    const element = [
        <>
            <Breadcrumbs aria-label="breadcrumb" style={{ padding: '20px' }}>
                <Link underline="hover" to={'/invoice'} color="inherit" style={{ color: '#19A73F', textDecoration: 'none', fontSize: '14px' }} >
                    Invoice
                </Link>
                <Typography style={{ color: '#000', fontSize: '14px' }}>
                    Mapping Instructions
                </Typography>
            </Breadcrumbs>
            <div className="content-container">
                <div className="containerford" style={{ marginTop: "10px" }}>
                    {/* <Typography style={{ color: '#19a73f', fontSize: '20px', fontWeight: '500' }}>
                        Invoice Mapping Instruction
                    </Typography> */}
                    <div className="first_conteent" style={{ marginTop: "4em" }}>
                        <MappingIntsructionTable
                            rows={data}
                            columns={columns}
                            headFilter={headFilter}
                            HiddenColumnlist={{}}
                            table={"Pos"}
                            isLoading={loading}
                            restname={restname}
                        />
                    </div>
                </div>
            </div>
        </>
    ]
    return (
        <>
            <ToastContainer autoClose={1000} />
            <Header
                handleMobileMenuClose={handleMobileMenuClose}
                anchorEl={anchorEl}
                handleProfileMenuOpen={handleProfileMenuOpen}
                handleMenuClose={handleMenuClose}
                loginUserName={loginUserName}
                handleMenuCloseout={handleMenuCloseout}
                handleMobileMenuOpen={handleMobileMenuOpen}
                mobileMoreAnchorEl={mobileMoreAnchorEl}
                element={element}
            />
            <Modal
                key={1}
                title="Mapping Info"
                open={mappingInfo}
                width={800}
                onCancel={() => { setmappingInfo(false); }}
                footer={[
                    <div aria-hidden="true" style={{ display: 'flex', justifyContent: 'center', paddingBlock: '5px' }}>
                        <Button
                            type="primary"
                            style={{
                                backgroundColor: 'rgb(239 239 239)',
                                color: '#000',
                                borderRadius: '3px',
                                border: '0.5px solid #b2b2b25c',
                            }}
                            onClick={() => { setmappingInfo(false); }}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            style={{
                                backgroundColor: '#19A73F',
                                color: '#fff',
                                borderRadius: '3px',
                                border: 'none'
                            }}
                            onClick={() => { ReMapCategory(reallocateOption, changedLineItemList) }}
                        >
                            Confirm
                        </Button>
                    </div>
                ]}
            >
                <div aria-hidden="true">
                    <div className='description_Table'>
                        <table className="description-table">
                            <tbody>
                                <tr>
                                    <td>Rest Name</td>
                                    <td>:</td>
                                    <td>{selectedRestaurant?.split('_')[0]}</td>
                                </tr>
                                <tr>
                                    <td>Purveyor</td>
                                    <td>:</td>
                                    <td>{changedLineItemList?.Purveyor}</td>
                                </tr>
                                <tr>
                                    <td>Description</td>
                                    <td>:</td>
                                    <td>{changedLineItemList?.Details}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <p>
                            The Description is being remapped to <b>{changedLineItemList?.new_cat?.split('_')[0]}</b>. Previously, the item has been mapped to the following {statistics.length > 1 ? `categories` : `category`}.
                        </p>
                    </div>
                    <div className='category_Table'>
                        <table className="caegory-table" style={{ border: '1px solid #ddd' }}>
                            <thead>
                                <tr>
                                    <th>Mapped Fobe Category</th>
                                    <th>No of Entries</th>
                                </tr>
                            </thead>
                            <tbody>
                                {statistics.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.Cat_name}</td>
                                        <td>{item.ItemCount}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <div>
                            <p>
                                Please review the information and confirm if you would like to proceed with the remapping.
                            </p>
                            <div style={{ marginLeft: '3em' }}>
                                <Radio.Group value={reallocateOption} onChange={handleReallocatopnChange}>
                                    <Radio value={1}> All historical and future data will be mapped to the new category.</Radio>
                                    <Radio value={2}> Only future data will be mapped to the new category. Historical data will remain unchanged.</Radio>
                                </Radio.Group>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Drawer
                title={<><span style={{ color: "#19A73F" }}>Mapping History</span></>}
                width={600}
                onClose={onClose}
                open={open}
                closeIcon={
                    <KeyboardArrowRightOutlinedIcon style={{ color: "#19A73F" }} />
                }
            >
                <div>
                    <div className='description_Table'>
                        <table className="description-table">
                            <tbody>
                                <tr>
                                    <td>Rest Name</td>
                                    <td>:</td>
                                    <td>{selectedRestaurant?.split('_')[0]}</td>
                                </tr>
                                <tr>
                                    <td>Purveyor</td>
                                    <td>:</td>
                                    <td>{changedLineItemList?.Purveyor}</td>
                                </tr>
                                <tr>
                                    <td>Description</td>
                                    <td>:</td>
                                    <td>{changedLineItemList?.Details}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <hr />
                    <div className="Log--Details">
                        {hasSkeletonShow ?
                            (<Skeleton
                                avatar
                                active
                                paragraph={{
                                    rows: 4,
                                }}
                            />)
                            :
                            (
                                <>
                                    <ChangeLog
                                        data={changeLogList}
                                    />
                                </>
                            )
                        }

                    </div>
                </div>
            </Drawer>
        </>
    );
};
export default MappingIntsruction;